import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, TextField, MenuItem, Select, InputLabel } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";

import CrudService from "services/cruds-service";
import { useNavigate } from "react-router-dom";

const NewQuote = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [quoteDetails, setQuoteDetails] = useState({
    discount: 0,
    total: 0,
    status: "Pending",
  });

  const [error, setError] = useState({
    user: false,
    items: false,
    discount: false,
    status: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const userResponse = await CrudService.getUsers(); // Fetch users from the API
        setUsers(userResponse.data);

        const itemResponse = await CrudService.getItems(); // Fetch items from the API
        setItems(itemResponse.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    })();
  }, []);

  const handleItemChange = (event, newValue) => {
    setSelectedItems(newValue);
    const totalAmount = newValue.reduce(
      (acc, item) => acc + item.attributes.price * item.quantity,
      0
    );
    setQuoteDetails({ ...quoteDetails, total: totalAmount });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      setError({ ...error, user: true, textError: "O usuário é obrigatório" });
      return;
    }

    if (selectedItems.length === 0) {
      setError({ ...error, items: true, textError: "Ao menos um item é obrigatório" });
      return;
    }

    const newQuote = {
      data: {
        type: "quotes",
        attributes: {
          discount: quoteDetails.discount,
          total: quoteDetails.total,
          status: quoteDetails.status,
        },
        relationships: {
          client: {
            data: {
              type: "users",
              id: selectedUser.id,
            },
          },
          items: selectedItems.map((item) => ({
            type: "items",
            id: item.id,
            quantity: item.quantity,
          })),
        },
      },
    };

    try {
      await CrudService.createQuote(newQuote); // Create new quote
      navigate("/quotes-management", {
        state: { value: true, text: "O orçamento foi criado com sucesso" },
      });
    } catch (err) {
      if (err.hasOwnProperty("errors")) {
        setError({ ...error, error: true, textError: err.errors[0].detail });
      }
      console.error("Error creating quote:", err);
    }
  };

  const dataTableData = {
    columns: [
      { Header: "Nome do Item", accessor: "name", width: "25%" },
      { Header: "Preço", accessor: "price", width: "15%" },
      { Header: "Quantidade", accessor: "quantity", width: "15%" },
      { Header: "Total", accessor: "total", width: "15%" },
    ],
    rows: selectedItems.map((item) => ({
      name: item.attributes.name,
      price: item.attributes.price,
      quantity: item.quantity,
      total: item.attributes.price * item.quantity,
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Adicionar Novo Orçamento
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Esta informação descreverá mais sobre o orçamento.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <MDBox display="flex" flexDirection="column">
                    <InputLabel id="user-label">Usuário</InputLabel>
                    <Autocomplete
                      options={users}
                      getOptionLabel={(option) => option.attributes.name}
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" error={error.user} />
                      )}
                    />
                    {error.user && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox mt={2}>
                    <InputLabel id="items-label">Itens</InputLabel>
                    <Autocomplete
                      multiple
                      options={items}
                      getOptionLabel={(option) => option.attributes.name}
                      value={selectedItems}
                      onChange={handleItemChange}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" error={error.items} />
                      )}
                    />
                    {error.items && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox mt={4}>
                    <DataTable table={dataTableData} />
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <FormField
                      label="Desconto"
                      placeholder="Desconto"
                      name="discount"
                      value={quoteDetails.discount}
                      onChange={(e) =>
                        setQuoteDetails({ ...quoteDetails, discount: e.target.value })
                      }
                      error={error.discount}
                    />
                    {error.discount && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <FormField
                      label="Total"
                      placeholder="Total"
                      name="total"
                      value={quoteDetails.total}
                      onChange={() => {}} // Total is calculated automatically based on items
                      error={error.total}
                      disabled
                    />
                    {error.total && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      name="status"
                      value={quoteDetails.status}
                      onChange={(e) =>
                        setQuoteDetails({ ...quoteDetails, status: e.target.value })
                      }
                    >
                      <MenuItem value="Pending">Pendente</MenuItem>
                      <MenuItem value="Approved">Aprovado</MenuItem>
                      <MenuItem value="Rejected">Rejeitado</MenuItem>
                    </Select>
                    {error.status && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  {error.error && (
                    <MDTypography variant="caption" color="error" fontWeight="light" pt={2}>
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() => navigate("/quotes-management")}
                      >
                        Voltar
                      </MDButton>
                    </MDBox>
                    <MDButton variant="gradient" color="dark" size="small" type="submit">
                      Salvar
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewQuote;
