/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* O aviso de direitos autorais acima e esta permissão devem ser incluídos em todas as cópias ou partes substanciais do Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Header from "cruds/user-profile/components/Header";
import BasicInfo from "cruds/user-profile/components/BasicInfo";
import ChangePassword from "cruds/user-profile/components/ChangePassword";
import AddressInfo from "cruds/user-profile/components/AddressInfo"; // Novo componente para informações de endereço
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AuthService from "services/auth-service";
import getId from "services/helper-service";
import Footer from "examples/Footer";

function UserProfile() {
  const [isDemo, setIsDemo] = useState(false);
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
    password: "",
    newPassword: "",
    currentPassword: "",
    confirmPassword: "",
    profile_image: "",
    address: {
      street: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
      postalCode: "",
    },
  });

  useEffect(() => {
    (async () => {
      const response = await AuthService.getProfile();
      if (getId(response.data.id) == 1 || getId(response.data.id) == 2 || getId(response.data.id) == 3) {
        setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
      }
      setUser((prevUser) => ({
        ...prevUser,
        id: response.data.id,
        name: response.data.attributes.name,
        email: response.data.attributes.email,
        profile_image: response.data.attributes.profile_image,
        address: {
          street: response.data.attributes.address.street,
          number: response.data.attributes.address.number,
          complement: response.data.attributes.address.complement,
          district: response.data.attributes.address.district,
          city: response.data.attributes.address.city,
          state: response.data.attributes.address.state,
          postalCode: response.data.attributes.address.postalCode,
        },
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }));
    })();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header user={user} isDemo={isDemo} />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo user={user} isDemo={isDemo} />
                </Grid>
                <Grid item xs={12}>
                  <AddressInfo user={user} isDemo={isDemo} />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword user={user} isDemo={isDemo} />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserProfile;
