// AddressInfo.js
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/applications/wizard/components/FormField";

import AuthService from "services/auth-service";

function AddressInfo({ user, isDemo }) {
  const [address, setAddress] = useState(user.address);
  const [error, setError] = useState({
    street: false,
    number: false,
    district: false,
    city: false,
    state: false,
    postalCode: false,
  });

  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedUser = {
      ...user,
      address,
    };

    try {
      await AuthService.updateProfile(updatedUser);
      alert("Endereço atualizado com sucesso!");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setAddress(user.address);
  }, [user]);

  return (
    <Card>
      <MDBox pt={3} px={2} pb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Informações de Endereço
        </MDTypography>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Rua"
                name="street"
                value={address.street}
                onChange={handleChange}
                error={error.street}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Número"
                name="number"
                value={address.number}
                onChange={handleChange}
                error={error.number}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Complemento"
                name="complement"
                value={address.complement}
                onChange={handleChange}
                error={error.complement}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Bairro"
                name="district"
                value={address.district}
                onChange={handleChange}
                error={error.district}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Cidade"
                name="city"
                value={address.city}
                onChange={handleChange}
                error={error.city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Estado"
                name="state"
                value={address.state}
                onChange={handleChange}
                error={error.state}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="CEP"
                name="postalCode"
                value={address.postalCode}
                onChange={handleChange}
                error={error.postalCode}
              />
            </Grid>
          </Grid>
          <MDBox mt={2}>
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
              disabled={isDemo}
            >
              Salvar
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default AddressInfo;
