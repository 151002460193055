import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Select, MenuItem, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate, useParams } from "react-router-dom";
import CrudService from "services/cruds-service";

const EditQuote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [quoteDetails, setQuoteDetails] = useState({
    discount: 0,
    total: 0,
    status: "Pending",
    approvalDate: null,
    rejectionDate: null,
    revisionRequestedDate: null,
  });

  const [error, setError] = useState({
    client: false,
    items: false,
    discount: false,
    status: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        // Load users and items
        const userResponse = await CrudService.getUsers();
        setUsers(userResponse.data);

        const itemResponse = await CrudService.getItems();
        setItems(itemResponse.data);

        // Load quote details
        const quoteResponse = await CrudService.getQuote(id);
        const quoteData = quoteResponse.data;

        setSelectedUser(quoteData.relationships.client.data.id);
        setSelectedItems(
          quoteData.relationships.items.data.map(item => ({
            id: item.id,
            quantity: item.attributes.quantity,
            attributes: item.attributes,
          }))
        );
        setQuoteDetails({
          discount: quoteData.attributes.discount,
          total: quoteData.attributes.total,
          status: quoteData.attributes.status,
          approvalDate: quoteData.attributes.approvalDate,
          rejectionDate: quoteData.attributes.rejectionDate,
          revisionRequestedDate: quoteData.attributes.revisionRequestedDate,
        });

      } catch (err) {
        console.error('Erro ao carregar os dados:', err);
      }
    })();
  }, [id]);

  const handleItemChange = (event, newValue) => {
    setSelectedItems(newValue);
    const totalAmount = newValue.reduce(
      (acc, item) => acc + item.attributes.price * item.quantity,
      0
    );
    setQuoteDetails({ ...quoteDetails, total: totalAmount });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      setError({ ...error, client: true, textError: "O cliente é obrigatório" });
      return;
    }

    if (selectedItems.length === 0) {
      setError({ ...error, items: true, textError: "Ao menos um item é obrigatório" });
      return;
    }

    const updatedQuote = {
      data: {
        type: "quotes",
        id: id.toString(),
        attributes: {
          discount: quoteDetails.discount,
          total: quoteDetails.total,
          status: quoteDetails.status,
          approvalDate: quoteDetails.approvalDate,
          rejectionDate: quoteDetails.rejectionDate,
          revisionRequestedDate: quoteDetails.revisionRequestedDate,
        },
        relationships: {
          client: {
            data: {
              type: "users",
              id: selectedUser,
            },
          },
          items: selectedItems.map(item => ({
            type: "items",
            id: item.id,
            quantity: item.quantity,
          })),
        },
      },
    };

    try {
      await CrudService.updateQuote(updatedQuote, id);
      navigate("/quote-management", {
        state: { value: true, text: "O orçamento foi atualizado com sucesso" },
      });
    } catch (err) {
      setError({ ...error, error: true, textError: 'Erro ao atualizar o orçamento.' });
      console.error(err);
    }
  };

  const dataTableData = {
    columns: [
      { Header: "Nome do Item", accessor: "name", width: "25%" },
      { Header: "Preço", accessor: "price", width: "15%" },
      { Header: "Quantidade", accessor: "quantity", width: "15%" },
      { Header: "Total", accessor: "total", width: "15%" },
    ],
    rows: selectedItems.map(item => ({
      name: item.attributes.name,
      price: item.attributes.price,
      quantity: item.quantity,
      total: item.attributes.price * item.quantity,
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Editar Orçamento
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Atualize as informações do orçamento.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <MDBox display="flex" flexDirection="column">
                    <InputLabel id="user-label">Cliente</InputLabel>
                    <Autocomplete
                      options={users}
                      getOptionLabel={(option) => option.attributes.name}
                      value={users.find(user => user.id === selectedUser) || null}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue ? newValue.id : null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" error={error.client} />
                      )}
                    />
                    {error.client && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox mt={2}>
                    <InputLabel id="items-label">Itens</InputLabel>
                    <Autocomplete
                      multiple
                      options={items}
                      getOptionLabel={(option) => option.attributes.name}
                      value={selectedItems.map(selected => items.find(item => item.id === selected.id) || selected)}
                      onChange={handleItemChange}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" error={error.items} />
                      )}
                    />
                    {error.items && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox mt={4}>
                    <DataTable table={dataTableData} />
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <InputLabel id="discount-label">Desconto</InputLabel>
                    <TextField
                      id="discount"
                      value={quoteDetails.discount}
                      onChange={(e) =>
                        setQuoteDetails({ ...quoteDetails, discount: e.target.value })
                      }
                      variant="standard"
                      error={error.discount}
                    />
                    {error.discount && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <InputLabel id="total-label">Total</InputLabel>
                    <TextField
                      id="total"
                      value={quoteDetails.total}
                      onChange={() => {}} // Total is calculated automatically based on items
                      variant="standard"
                      error={error.total}
                      disabled
                    />
                    {error.total && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      name="status"
                      value={quoteDetails.status}
                      onChange={(e) => setQuoteDetails({ ...quoteDetails, status: e.target.value })}
                    >
                      <MenuItem value="Pending">Pendente</MenuItem>
                      <MenuItem value="Approved">Aprovado</MenuItem>
                      <MenuItem value="Rejected">Rejeitado</MenuItem>
                      <MenuItem value="revision_requested">Revisão Solicitada</MenuItem>
                    </Select>
                    {error.status && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  {error.error && (
                    <MDTypography variant="caption" color="error" fontWeight="light" pt={2}>
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() => navigate("/quote-management")}
                      >
                        Voltar
                      </MDButton>
                    </MDBox>
                    <MDButton variant="gradient" color="dark" size="small" type="submit">
                      Salvar
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditQuote;
