import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import CrudService from "services/cruds-service"; // Importing the instance of CrudService

function ProductCell({ itemId }) {
  const [item, setItem] = useState({ image: '', name: '', orders: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await CrudService.getItem(itemId);
        setItem(data || { image: '', name: '', orders: 0 }); // Default values if data is null
        setError('');
      } catch (error) {
        console.error('Error fetching item details:', error);
        setError('Failed to fetch item details');
      } finally {
        setLoading(false);
      }
    };

    if (itemId) fetchData();  // Ensuring itemId is not undefined
  }, [itemId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDBox mr={2}>
        <MDAvatar src={item.image || 'default-image.jpg'} alt={item.name || 'No Name'} />
      </MDBox>
      <MDBox display="flex" flexDirection="column">
        <MDTypography variant="button" fontWeight="medium">
          {item.name || 'Unnamed Item'}
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="secondary">
          <MDTypography component="span" variant="button" fontWeight="regular" color="success">
            {item.orders || 0}
          </MDTypography> orders
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

ProductCell.propTypes = {
  itemId: PropTypes.string.isRequired,
};

export default ProductCell;
