/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faNode } from "@fortawesome/free-brands-svg-icons";

import UserProfile from "cruds/user-profile";
import RoleManagement from "cruds/role-managament";
import CategoryManagement from "cruds/category-management";
import TagManagement from "cruds/tag-management";
import UserManagement from "cruds/user-management";
import ItemManagement from "cruds/item-management";
import QuotesManagement from "cruds/quotes-management";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

const routes = [
  {
    type: "collapse",
    name: "Bruce Mars",
    key: "user-name",
    icon: <MDAvatar src={profilePicture} alt="Bruce Mars" size="sm" />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Opções",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Resumo de Vndas",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
      {
        name: "Sair",
        key: "logout",
        route: "/auth/logout",
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Pedidos", key: "pedidos" },
  {
    type: "collapse",
    name: "Pedidos",
    key: "orders",
    icon: <Icon fontSize="medium">shopping_cart</Icon>,
    collapse: [
      {
        name: "Lista de Pedidos",
        key: "order-list",
        route: "/ecommerce/orders/order-list",
        component: <OrderList />,
      },
      {
        name: "Detalhes do Pedido",
        key: "order-details",
        route: "/ecommerce/orders/order-details",
        component: <OrderDetails />,
      },
      {
        name: "Orçamentos",
        key: "quotes-management",
        route: "/examples-api/quotes-management",
        component: <QuotesManagement />,
        type: "quotes",
      },
    ],
  },
  { type: "title", title: "Funções", key: "crud-pages" },
  {
    type: "collapse",
    name: "Controle",
    key: "react-nodejs",
    icon: <FontAwesomeIcon icon={faNode} size="sm" />,
    collapse: [
      {
        name: "Perfil",
        key: "user-profile",
        route: "/examples-api/user-profile",
        component: <UserProfile />,
      },
      {
        name: "Clientes",
        key: "user-management",
        route: "/examples-api/user-management",
        component: <UserManagement />,
        type: "users",
      },
      {
        name: "Cargos/Permissões",
        key: "role-management",
        route: "/examples-api/role-management",
        component: <RoleManagement />,
        type: "roles",
      },
      {
        name: "Categorias",
        key: "category-management",
        route: "/examples-api/category-management",
        component: <CategoryManagement />,
        type: "categories",
      },
      {
        name: "Tag's de Produtos",
        key: "tag-management",
        route: "/examples-api/tag-management",
        component: <TagManagement />,
        type: "tags",
      },
      {
        name: "Produtos",
        key: "item-management",
        route: "/examples-api/item-management",
        component: <ItemManagement />,
        type: "items",
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Quadro de Tarefas",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
    ],
  },
];

export default routes;
