import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { Tooltip, IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import CrudService from "services/cruds-service";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import { Can } from "Can";
import { subject } from "@casl/ability";

function QuoteManagement() {
  let { state } = useLocation();
  const [isDemo, setIsDemo] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await CrudService.getQuotes(); // Obtenção das cotações
        setQuotes(response.data || []); // Garante que 'quotes' seja sempre um array
        setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
      } catch (err) {
        console.error("Erro ao carregar cotações:", err);
        setQuotes([]);
      }
    })();
  }, []);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (quotes.length > 0) {
      setTableData(getRows(quotes));
    }
  }, [quotes]);

  useEffect(() => {
    if (notification.value === true) {
      let timer = setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    navigate("/quotes-management/new-quote");
  };

  const clickEditHandler = (id) => {
    navigate(`/quotes-management/edit-quote/${id}`);
  };

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!confirm("Você tem certeza que deseja deletar esta cotação?")) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.deleteQuote(id); // Exclusão da cotação
        const response = await CrudService.getQuotes(); // Recarregar cotações
        setQuotes(response.data || []); // Garante que 'quotes' seja sempre um array
        setNotification({
          value: true,
          text: "A cotação foi deletada com sucesso",
        });
      }
    } catch (err) {
      console.error("Erro ao deletar cotação:", err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const getRows = (info) => {
    if (!info || !Array.isArray(info)) {
      return []; // Garante que 'info' seja um array válido antes de aplicar 'map'
    }
    return info.map((row) => ({
      id: row.id,
      quote_number: row.attributes?.quote_number || "N/A",
      customer_name: row.attributes?.customer_name || "N/A",
      total: row.attributes?.total || "N/A",
      status: row.attributes?.status || "N/A",
      created_at: row.attributes?.created_at || "N/A",
    }));
  };

  const dataTableData = {
    columns: [
      { Header: "Número da Cotação", accessor: "quote_number", width: "20%" },
      { Header: "Nome do Cliente", accessor: "customer_name", width: "25%" },
      { Header: "Valor Total", accessor: "total", width: "15%" },
      { Header: "Status", accessor: "status", width: "15%" },
      { Header: "Criado em", accessor: "created_at", width: "15%" },
      {
        Header: "ações",
        disableSortBy: true,
        accessor: "",
        Cell: (info) => (
          <MDBox display="flex" alignItems="center">
            <Can I="delete" this={subject("quotes", info.cell.row.original)}>
              <Tooltip
                title="Deletar Cotação"
                onClick={(e) => clickDeleteHandler(e, info.cell.row.original.id)}
              >
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Can>
            <Can I="edit" this={subject("quotes", info.cell.row.original)}>
              <Tooltip
                title="Editar Cotação"
                onClick={() => clickEditHandler(info.cell.row.original.id)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Can>
          </MDBox>
        ),
      },
    ],

    rows: tableData,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                Gerenciamento de Cotações
              </MDTypography>
              {ability.can("create", "quotes") && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={clickAddHandler}
                >
                  + Adicionar Cotação
                </MDButton>
              )}
            </MDBox>
            <DataTable table={dataTableData} />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default QuoteManagement;
