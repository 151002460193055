import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, FormLabel, InputLabel, Select, MenuItem } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDEditor from "components/MDEditor";
import { Autocomplete, RadioGroup, FormControlLabel, Radio, Switch, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from 'date-fns/locale';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate, useParams } from "react-router-dom";

import CrudService from "services/cruds-service";

const EditItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [chosenTags, setChosenTags] = useState([]);
  const [image, setImage] = useState("");
  const [fileState, setFileState] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [description, setDescription] = useState("");
  const [item, setItem] = useState({
    id: "",
    name: "",
    excerpt: "",
    homepage: false,
    category: "",
    date: new Date(), // Inicialize como um objeto Date
    status: "",
    quantityUnit: "",
    quantity: 0,
    price: 0,
    SKU: "",
    isExisting: false,
    existingItem: "",
  });

  const [error, setError] = useState({
    name: false,
    excerpt: false,
    homepage: false,
    category: false,
    tags: false,
    date: false,
    status: false,
    description: false,
    image: false,
    quantityUnit: false,
    quantity: false,
    price: false,
    SKU: false,
    existingItem: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await CrudService.getCategories();
        setCategories(response.data);
      } catch (err) {
        console.error(err);
        return null;
      }
      try {
        const response = await CrudService.getTags();
        setTags(response.data);
      } catch (err) {
        console.error(err);
        return null;
      }
      try {
        const response = await CrudService.getItems();
        setExistingItems(response.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const response = await CrudService.getItem(id);
        const resData = response.data.attributes;
        setItem({
          id: response.data.id,
          name: resData.name,
          excerpt: resData.excerpt,
          homepage: resData.is_on_homepage,
          date: new Date(resData.date_at), // Certifique-se de que a data seja um objeto Date
          status: resData.status,
          quantityUnit: resData.quantityUnit,
          quantity: resData.quantity,
          price: resData.price,
          SKU: resData.SKU,
          isExisting: resData.isExisting,
          existingItem: resData.existingItem || "",
        });
        setDescription(resData.description);
        setImage(resData.image);
        const categoryData = await CrudService.getCategory(resData.category_id);
        const category = categoryData.data;
        setValue(category);
        response.included.forEach((res) => {
          if (res.type === "categories") {
            setItem((prevState) => {
              return { ...prevState, category: res.id };
            });
          }
          if (res.type === "tags") {
            setChosenTags((prevState) => [...prevState, res]);
          }
        });
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, [id]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "isExisting") {
      setItem({
        ...item,
        isExisting: e.target.checked,
        existingItem: e.target.checked ? item.existingItem : "",
      });
    } else {
      setItem({
        ...item,
        [name]: value,
      });
    }
  };

  const changeImageHandler = (e) => {
    const formData = new FormData();
    formData.append("attachment", e.target.files[0]);
    setFileState(formData);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (item.name.trim().length === 0) {
      setError({
        ...error,
        name: true,
        textError: "O nome é obrigatório",
      });
      return;
    }

    if (item.excerpt.trim().length === 0) {
      setError({
        ...error,
        excerpt: true,
        textError: "O resumo é obrigatório",
      });
      return;
    }

    let descNoTags = description.replace(/(<([^>]+)>)/gi, "");
    if (descNoTags.length === 0) {
      setError({
        ...error,
        description: true,
        textError: "A descrição é obrigatória",
      });
      return;
    }

    if (!item.category) {
      setError({
        ...error,
        category: true,
        textError: "A categoria é obrigatória",
      });
      return;
    }

    if (chosenTags.length < 1) {
      setError({
        ...error,
        tags: true,
        textError: "As tags são obrigatórias",
      });
      return;
    }

    if (item.status.length < 1) {
      setError({
        ...error,
        status: true,
        textError: "O status é obrigatório",
      });
      return;
    }

    if (!image) {
      setError({
        ...error,
        image: true,
        textError: "A imagem é obrigatória",
      });
      return;
    }

    if (item.quantityUnit.length < 1) {
      setError({
        ...error,
        quantityUnit: true,
        textError: "A unidade de quantidade é obrigatória",
      });
      return;
    }

    if (item.quantity <= 0) {
      setError({
        ...error,
        quantity: true,
        textError: "A quantidade deve ser maior que zero",
      });
      return;
    }

    if (item.price <= 0) {
      setError({
        ...error,
        price: true,
        textError: "O preço deve ser maior que zero",
      });
      return;
    }

    if (item.SKU.trim().length === 0) {
      setError({
        ...error,
        SKU: true,
        textError: "O SKU é obrigatório",
      });
      return;
    }

    if (item.isExisting && !item.existingItem) {
      setError({
        ...error,
        existingItem: true,
        textError: "Selecione um item existente",
      });
      return;
    }

    try {
      const { url } = fileState ? await CrudService.itemImageUpload(fileState, id) : image;

      const newItem = {
        data: {
          type: "items",
          id: item.id.toString(),
          attributes: {
            name: item.name,
            excerpt: item.excerpt,
            description,
            is_on_homepage: item.homepage,
            image: fileState ? `${process.env.REACT_APP_IMAGES}${url}` : image,
            status: item.status,
            date_at: item.date.toISOString(), // Convertendo a data para string ISO no momento de envio
            quantityUnit: item.quantityUnit,
            quantity: item.quantity,
            price: item.price,
            SKU: item.SKU,
            existingItem: item.isExisting ? item.existingItem : null,
          },
          relationships: {
            category: {
              data: {
                type: "categories",
                id: item.category.toString(),
              },
            },
            user: {
              data: {
                type: "users",
                id: "1",
              },
            },
            tags: {
              data: chosenTags.map((tag) => {
                return { type: "tags", id: tag.id };
              }),
            },
          },
        },
      };
      try {
        const res = await CrudService.updateItem(newItem, newItem.data.id);
        navigate("/examples-api/item-management", {
          state: { value: true, text: "O item foi atualizado com sucesso" },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        return null;
      }
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle={item.name} />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Editar Item
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Esta informação descreverá mais sobre o item.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <MDBox display="flex" flexDirection="column">
                    <FormField
                      label="Nome"
                      placeholder="Nome do Produto"
                      name="name"
                      value={item.name}
                      onChange={changeHandler}
                      error={error.name}
                    />
                    {error.name && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <FormField
                      label="Resumo"
                      placeholder="Resumo do Produto"
                      name="excerpt"
                      value={item.excerpt}
                      onChange={changeHandler}
                      error={error.excerpt}
                    />
                    {error.excerpt && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox mt={2}>
                    <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Descrição&nbsp;&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDEditor value={description} onChange={setDescription} />
                    {error.description && (
                      <MDTypography variant="caption" color="error" fontWeight="light">
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" fullWidth>
                    <MDBox display="flex" flexDirection="column" fullWidth marginTop="2rem">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Categoria
                      </MDTypography>
                      <Autocomplete
                        defaultValue={null}
                        options={categories}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.attributes) {
                              if (option.data.attributes.name) return option.data.attributes.name;
                            }
                          } else {
                            if (option.attributes) {
                              if (option.attributes.name) return option.attributes.name;
                            }
                          }
                          return "";
                        }}
                        value={value ?? null}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => (
                          <FormField {...params} label="" InputLabelProps={{ shrink: true }} />
                        )}
                      />
                      {error.category && (
                        <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" fullWidth marginTop="2rem">
                    <MDBox display="flex" flexDirection="column" fullWidth>
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Tags
                      </MDTypography>
                      <Autocomplete
                        multiple
                        value={tags.filter((tag) => {
                          if (chosenTags.find((choice) => choice.id === tag.id)) {
                            return <span style={{ backgroundColor: tag.color }}>{tag}</span>;
                          }
                        })}
                        getOptionLabel={(option) => option.attributes.name}
                        options={tags}
                        filterSelectedOptions={true}
                        onChange={(e, value) => {
                          setChosenTags(value);
                        }}
                        renderInput={(params) => <MDInput {...params} variant="standard" />}
                      />
                      {error.tags && (
                        <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="column">
                    <FormControl>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        sx={{ fontSize: "0.875rem", fontWeight: "400", mt: "24px" }}
                      >
                        Status
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={item.status}
                        name="radio-buttons-group"
                        onChange={(e) => setItem({ ...item, status: e.target.value })}
                      >
                        <FormControlLabel
                          sx={{ fontSize: "0.875rem", fontWeight: "400" }}
                          value="published"
                          control={<Radio />}
                          label="Publicado"
                          checked={item.status === "published"}
                        />
                        <FormControlLabel
                          sx={{ fontSize: "0.875rem", fontWeight: "400" }}
                          value="draft"
                          control={<Radio />}
                          label="Rascunho"
                          checked={item.status === "draft"}
                        />
                        <FormControlLabel
                          sx={{ fontSize: "0.875rem", fontWeight: "400" }}
                          value="archive"
                          control={<Radio />}
                          label="Arquivo"
                          checked={item.status === "archive"}
                        />
                      </RadioGroup>
                    </FormControl>
                    {error.status && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={2}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" alignItems="center" mb={3} ml={-1.5}>
                    <MDBox mt={0.5}>
                      <Switch
                        checked={item.homepage}
                        onChange={(e) => setItem({ ...item, homepage: e.target.checked })}
                        name="homepage"
                      />
                    </MDBox>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        Homepage
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                    <DesktopDatePicker
                      label="Data"
                      inputFormat="dd/MM/yyyy"
                      name="date"
                      value={item.date} // Use um objeto Date
                      onChange={(newValue) => setItem({ ...item, date: newValue })}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <FormControl fullWidth>
                      <InputLabel id="quantity-unit-label">Unidade de Quantidade</InputLabel>
                      <Select
                        labelId="quantity-unit-label"
                        id="quantityUnit"
                        name="quantityUnit"
                        value={item.quantityUnit}
                        onChange={changeHandler}
                      >
                        <MenuItem value="Meters">Metros</MenuItem>
                        <MenuItem value="Kg">Kg</MenuItem>
                        <MenuItem value="g">g</MenuItem>
                        <MenuItem value="Centimeters">Centímetros</MenuItem>
                        <MenuItem value="Unit">Unidade</MenuItem>
                      </Select>
                      {error.quantityUnit && (
                        <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                          {error.textError}
                        </MDTypography>
                      )}
                    </FormControl>
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <FormField
                      label="Quantidade"
                      placeholder="Quantidade"
                      name="quantity"
                      value={item.quantity}
                      onChange={changeHandler}
                      error={error.quantity}
                    />
                    {error.quantity && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <FormField
                      label="Preço"
                      placeholder="Preço"
                      name="price"
                      value={item.price}
                      onChange={changeHandler}
                      error={error.price}
                    />
                    {error.price && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" flexDirection="column" mt={2}>
                    <FormField
                      label="SKU"
                      placeholder="SKU"
                      name="SKU"
                      value={item.SKU}
                      onChange={changeHandler}
                      error={error.SKU}
                    />
                    {error.SKU && (
                      <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox display="flex" alignItems="center" mt={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={item.isExisting}
                          onChange={changeHandler}
                          name="isExisting"
                        />
                      }
                      label="Produto Existente"
                    />
                  </MDBox>

                  {item.isExisting && (
                    <MDBox display="flex" flexDirection="column" mt={2}>
                      <Autocomplete
                        options={existingItems}
                        getOptionLabel={(option) => option.attributes.name}
                        value={existingItems.find((prod) => prod.id === item.existingItem) || null}
                        onChange={(event, newValue) => {
                          setItem({ ...item, existingItem: newValue ? newValue.id : "" });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecionar Produto Existente"
                            variant="standard"
                          />
                        )}
                      />
                      {error.existingItem && (
                        <MDTypography variant="caption" color="error" fontWeight="light" pt={1}>
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  )}

                  <MDBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    fullWidth
                  >
                    <MDBox mt={2} display="flex" flexDirection="column">
                      <InputLabel id="demo-simple-select-label">Imagem</InputLabel>
                      <MDInput
                        fullWidth
                        type="file"
                        name="attachment"
                        onChange={changeImageHandler}
                        id="file-input"
                        accept="image/*"
                        sx={{ mt: "16px" }}
                      ></MDInput>
                      {error.image && (
                        <MDTypography variant="caption" color="error" fontWeight="light" pt={2}>
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>

                    {image && (
                      <MDBox ml={4} mt={2}>
                        <MDAvatar
                          src={imageUrl ?? image}
                          alt="item-image"
                          size="xxl"
                          shadow="sm"
                        />
                      </MDBox>
                    )}
                  </MDBox>
                  {error.error && (
                    <MDTypography variant="caption" color="error" fontWeight="light" pt={2}>
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/examples-api/item-management", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        Voltar
                      </MDButton>
                    </MDBox>
                    <MDButton variant="gradient" color="dark" size="small" type="submit">
                      Salvar
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditItem;
